<template>
  <b-card class="mb-4 shadow-sm" header="Password-based Access">
    <b-input-group>
      <b-input type="text" v-model="password" :readonly="revealing !== 3" />
      <b-input-group-append>
        <b-btn v-if="revealing === 2 || revealing === 3" variant="info" @click="hide">
          <icon-text icon="eye">Hide</icon-text>
        </b-btn>
        <b-btn v-else variant="info" @click="reveal" :disabled="revealing === 1 || revealing === 4">
          <fi v-if="revealing === 1" icon="spinner" spin />
          <icon-text v-else icon="eye">Reveal</icon-text>
        </b-btn>
        <b-btn v-if="revealing === 2" variant="info" @click="edit">
          <icon-text icon="key">Change</icon-text>
        </b-btn>
        <b-btn v-if="revealing === 3" variant="info" @click="update" :disabled="revealing === 4">
          <icon-text icon="key">Save</icon-text>
        </b-btn>
      </b-input-group-append>
    </b-input-group>
    <b-alert class="mt-2" v-if="updateStatus === 1" show variant="success">The guest password has been changed.</b-alert>
    <b-alert class="mt-2" v-else-if="updateStatus === 2" show variant="danger">An error occurred, and the password has not been changed..</b-alert>
    <p class="text-muted">For password-based access, the default bandwidth settings are always applied.</p>
  </b-card>
</template>

<script>
import IconText from '../../../components/IconText'

const REVEAL_HIDDEN = 0
const REVEAL_LOADING = 1
const REVEAL_VISIBLE = 2
const REVEAL_EDIT = 3
const REVEAL_UPDATING = 4

const UPDATE_STATUS_NONE = 0
const UPDATE_STATUS_OK = 1
const UPDATE_STATUS_ERROR = 2

export default {
  components: { IconText },
  data: () => ({
    password: '••••••••••••••••',
    revealing: REVEAL_HIDDEN,
    updateStatus: UPDATE_STATUS_NONE
  }),
  methods: {
    async reveal () {
      this.updateStatus = UPDATE_STATUS_NONE
      this.revealing = REVEAL_LOADING
      try {
        this.password = (await API.guest.reveal_password()).password
      } finally {
        this.revealing = REVEAL_VISIBLE
      }
    },
    hide () {
      this.updateStatus = UPDATE_STATUS_NONE
      this.password = '••••••••••••••••'
      this.revealing = REVEAL_HIDDEN
    },
    edit () {
      this.updateStatus = UPDATE_STATUS_NONE
      this.revealing = REVEAL_EDIT
    },
    async update () {
      this.updateStatus = UPDATE_STATUS_NONE
      this.revealing = REVEAL_UPDATING
      try {
        await API.guest.change_password({ password: this.password })
        this.password = '••••••••••••••••'
        this.revealing = REVEAL_HIDDEN
        this.updateStatus = UPDATE_STATUS_OK
      } catch (error) {
        this.revealing = REVEAL_EDIT
        this.updateStatus = UPDATE_STATUS_ERROR
      }
    }
  }
}
</script>
