<template>
  <div v-if="options">
    <b-alert v-if="!currentOptions.enabled" show variant="danger">Guest access is <strong>NOT</strong> enabled.</b-alert>
    <b-alert v-else-if="currentOptions.enabled && !currentOptions.modules.password.enabled && !currentOptions.modules.account" show variant="warning">Guest access <strong>IS</strong> enabled, but there are no authentication modules enabled.</b-alert>
    <b-alert v-else show variant="success">Guest access <strong>IS</strong> enabled.</b-alert>

    <b-card class="shadow-sm mb-4" header="Options">
      <b-checkbox v-model="options.enabled">Enable Guest WiFi access</b-checkbox>
      <hr>
      <h4>Login Modules</h4>
      <b-checkbox v-model="options.modules.password.enabled">Enable password-based access</b-checkbox>
      <b-checkbox v-model="options.modules.account">Enable account-based access</b-checkbox>
      <hr>
      <h4>Default Settings</h4>
      <b-row class="mb-2">
        <b-col cols="3">
          <b-input-group append="kbps">
            <b-input-group-prepend is-text><icon-text icon="arrow-up">Upload bandwidth</icon-text></b-input-group-prepend>
            <b-input type="number" v-model.number="options.settings.upload" :min="0" />
          </b-input-group>
        </b-col>

        <b-col cols="3">
          <b-input-group append="kbps">
            <b-input-group-prepend is-text><icon-text icon="arrow-down">Download bandwidth</icon-text></b-input-group-prepend>
            <b-input type="number" v-model.number="options.settings.download" :min="0" />
          </b-input-group>
        </b-col>

        <b-col cols="3">
          <b-input-group prepend="Session Length" append="minutes">
            <b-input type="number" v-model.number="options.settings.session_length" :min="60" />
          </b-input-group>
        </b-col>
      </b-row>
      <small class="text-muted">A value of <strong>zero</strong> in the upload or download rates indicates unlimited bandwidth.</small>

      <hr>
      <b-btn @click="saveOptions()">Save</b-btn>
    </b-card>

    <guest-activity />
    <password-based-access v-if="currentOptions.modules.password.enabled" />
    <account-based-access v-if="currentOptions.modules.account" />
  </div>
  <b-alert v-else-if="error" show variant="danger"><strong>Error:</strong> {{error}}</b-alert>
  <b-alert v-else show variant="info"><icon-text icon="spinner" spin>Loading...</icon-text></b-alert>
</template>

<script>
import IconText from '../../../components/IconText'
import PasswordBasedAccess from './PasswordBasedAccess'
import AccountBasedAccess from './AccountBasedAccess'
import GuestActivity from './GuestActivity'

export default {
  components: { IconText, PasswordBasedAccess, AccountBasedAccess, GuestActivity },
  data: () => ({
    currentOptions: null,
    options: null,
    error: null
  }),
  methods: {
    async reload () {
      this.options = null
      this.error = null
      try {
        this.currentOptions = await API.guest.get_options()
        this.options = JSON.parse(JSON.stringify(this.currentOptions))
      } catch (error) {
        this.error = error
      }
    },
    async saveOptions () {
      try {
        await API.guest.update_options(this.options)
        this.reload()
      } catch (error) {
        alert(error)
      }
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
