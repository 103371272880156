<template>
  <b-card class="shadow-sm mb-4" header="Activity" no-body>
    <b-overlay :show="busy">
      <b-card-body>
        <p>Active guests: <template v-if="activity">{{activity.guests.length}}</template><span class="text-muted" v-else>(loading)</span></p>
        <b-btn variant="secondary" @click="reload()" class="mr-2"><icon-text icon="sync">Refresh</icon-text></b-btn>
        <b-btn variant="danger" @click="disconnectAll()">Disconnect all guests</b-btn>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import IconText from '../../../components/IconText'

export default {
  components: { IconText },
  data: () => ({
    activity: null,
    busy: true
  }),
  methods: {
    async reload () {
      this.busy = true

      try {
        this.activity = await API.guest.get_activity()
      } finally {
        this.busy = false
      }
    },
    async disconnectAll () {
      this.busy = true

      try {
        this.activity = await API.guest.disconnect_all()
      } finally {
        this.busy = false
      }

      this.reload()
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
